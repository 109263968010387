
#about-liver, #silimarin, #choose-silimax, #good-advice, #kontakt {
  display: block;
  position: relative;
  top: -220px;
  visibility: hidden;

  @include MQ(Lneg) {
    top: -150px;
  }
}
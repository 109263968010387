.about-liver {

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .left, .right {
      width: calc(50% - 25px);

      @include MQ(Lneg) {
        width: 100%;
      }
    }

    h2 {
      color: $lemon;
      font-family: $PTSerif;
    }

    h2 + p {
      font-size: 3.2rem;
      font-family: $Barlow;
      font-weight: 600;
      margin-bottom: 80px;

      @include MQ(Lneg) {
        font-size: 2.2rem;
        margin-bottom: 40px;
      }
    }

    sub, sup {
      font-size: 1.2rem;
    }

    .about-box {
      box-shadow: 0 5px 10px #43435C1A;
      border-radius: 20px;
      background-color: $white;
      margin-bottom: 20px;
      padding: 30px;
      opacity: 0;

      h3 {
        font-size: 2.6rem;
        margin-bottom: 20px;
        font-weight: 600;
        color: $lemon;
      }

      p {
        font-size: 1.8rem;
        line-height: 1.5;
      }
    }

    .video-box {
      border-radius: 5px;
      overflow: hidden;

      video {
        width: 100%;
        display: block;
      }
    }

    .about-sources {
      font-size: 1.4rem;
      word-break: break-all;
    }

    .about-image {
      opacity: 0;

      img {
        width: 100%;

        @include MQ(Lneg) {
          max-width: 80%;
          display: block;
          margin: 60px auto 0;
        }
      }
    }
  }

  &.start-animation {
    .container {
      .about-image {
        animation-name: zoomInRight;
        animation-duration: .7s;
        animation-fill-mode: forwards;
      }

      .about-box {
        animation-name: zoomIn;
        animation-duration: .7s;
        animation-fill-mode: forwards;
        animation-delay: 0.5s;
      }
    }
  }
}

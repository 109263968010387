.good-advice {
  background-color: #F6F9E3;

  .container {

    h2 {
      color: $lemon;
      margin-bottom: 50px;
    }

    .good-advice-item {
      background-color: $white;
      border-radius: 20px;
      display: flex;
      margin-bottom: 20px;

      @include MQ(Lneg) {
        flex-direction: column;
        width: 80%;
        margin: 0 auto 20px;
      }

      @include MQ(Mneg) {
        width: 100%;
      }

      .good-advice-image {
        opacity: 0;
        border-radius: 20px;
        width: 50%;
        background: url('../img/dobre-rady.jpg') no-repeat center center;
        background-size: cover;

        @include MQ(Lneg) {
          height: 350px;
          width: 100%;
        }
      }

      .good-advice-description {
        width: 50%;
        padding: 55px 80px 45px 50px;
        opacity: 0;

        @include MQ(Lneg) {
         width: 100%;
        }

        @include MQ(Mneg) {
          padding: 20px;
        }

        h3 {
          font-family: $Barlow;
          color: $lemon;
          font-size: 2.6rem;
          margin-bottom: 20px;
        }

        p {
          font-size: 1.8rem;
          line-height: 1.55;
        }

        a {
          display: inline-block;
          padding: 10px 65px 10px 0;
          position: relative;
          font-size: 1.8rem;
          color: $lemon;
          margin-top: 30px;

          &:before {
            content: '';
            position: absolute;
            @include center(false, true);
            right: 0;
            background: url('../img/ico-arrow-right.svg') no-repeat center center;
            background-size: 44px 44px;
            width: 44px;
            height: 44px;
          }
        }
      }
    }
  }

  &.start-animation {

    .good-advice-image {
      animation-name: fadeInLeft;
      animation-duration: .7s;
      animation-fill-mode: forwards;
    }
    .good-advice-description  {
      animation-name: fadeInRight;
      animation-duration: .7s;
      animation-fill-mode: forwards;
    }
  }
}
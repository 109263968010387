.kontakt {
  background-color: $graphite;
  color: $white;
  font-size: 1.8rem;
  line-height: 1.55;

  a {
    color: white;
    text-decoration: underline;
  }

  .container {

    ul {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;

      li {

        strong {
          font-weight: 600;
        }
        &:nth-child(1) {
          width: 35%;
          padding-right: 20px;
        }
        &:nth-child(2) {
          width: 25%;
        }
        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 20%;
          text-align: right;

          @include MQ(Lneg) {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            font-size: 1.4rem;
          }

          @include MQ(Mneg) {
            margin-top: 20px;
          }
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          @include MQ(Lneg) {
            width: 50%;
          }

          @include MQ(Mneg) {
            width: 100%;
          }
        }

        &:nth-child(1),
        &:nth-child(2) {
          @include MQ(Lneg) {
             margin-bottom: 25px;
          }
        }
      }
    }
  }
}
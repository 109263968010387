.sil {
  background-color: $gray;
  font-size: 1.8rem;
  line-height: 1.55;

  .container {

   h2 {
     font-size: 2.6rem;
     margin-bottom: 20px;
   }

   p {
     font-size: 1.8rem;
     margin-bottom: 30px;

     strong {
       font-weight: 600;
       font-style: italic;
     }
   }
  }
}
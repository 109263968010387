
html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 62.5%;
}

*, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

body {
    position: relative;
    min-width: $min-width;
    font-size: 2rem;
    line-height: 1.4;
    font-family: $Barlow;
    font-weight: 500;
    color: $graphite;
    background-color: #FBFBFB;

    &.disable-scroll {
        @include MQ(Lneg){
        overflow: hidden;
        height: 100%;
        }
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: $PTSerif;
    line-height: 1.2;
    font-weight: bold;
}

h1 {

}

h2 {
    font-size: 4rem;

    @include MQ(Lneg) {
        font-size: 3rem;
    }
}

h3 {

}

h4 {

}

p {

}

a {
    color: $graphite;
    text-decoration: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    &[href^="tel:"] {
        text-decoration: none;
    }
}

strong {
    font-weight: 700;
}

input:focus {
    outline: none;
}



.cookies-alert {
    background-color: $white;
    color: $graphite;
    text-align: center;
    padding: 30px;
    display: none;

    .cookies-button {
        display: inline-block;
        width: 100px;
        height: auto;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
        font-size: 16px;
        line-height: 16px;
        border: 1px solid $graphite;
        background-color: $white;
        cursor: pointer;
        margin: 5px 20px 5px 20px;
        transition: all .3s ease-out;
        color: $graphite;

        &:hover {
            color: $white;
            background-color: $graphite;
        }
    }

    p {
        display: inline-block;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        margin: 5px;

        a {
            color: $graphite;
            text-decoration: underline;
        }
    }
}
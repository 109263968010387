.subpage {
    margin-top: 160px;

    @include MQ(Lneg) {
        margin-top: 90px;
    }

    .container {
        display: flex;

        .back-arrow {
            width: 200px;

            @include MQ(Lneg) {
                display: none;
            }

            a {
                display: inline-block;
                padding: 10px 10px 10px 60px;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    @include center(false, true);
                    left: 0;
                    width: 44px;
                    height: 44px;
                    background: url('../img/ico-back.svg') no-repeat center center;
                    background-size: 44px 44px;
                }
            }
        }

        .main-text {
            width: calc(100% - 200px);
            max-width: 1250px;
            background-color: $white;
            box-shadow: 0 5px 10px #43435C1A;
            border-radius: 20px;
            padding: 50px 70px;
            font-weight: 400;
            margin-bottom: 20px;

            @include MQ(Lneg) {
                width: 100%;
            }

            @include MQ(Mneg) {
                padding: 20px 30px;
            }

            h1 {
                color: $lemon;
                font-size: 4rem;
                margin-bottom: 30px;
            }

            strong {
                font-size: 2rem;
            }

            p.intro {
                font-size: 2rem;
                font-weight: 700;
                margin-bottom: 30px;
            }

            p {
                font-size: 1.8rem;
                margin-bottom: 30px;
            }

            figure.main-image {
                margin-bottom: 30px;

                img  {
                    width: 100%;
                    border-radius: 20px;
                    box-shadow: 0 5px 10px #43435C1A;
                }
            }

            ul {
                margin-bottom: 30px;

                li {
                    font-size: 1.8rem;
                    list-style-type: disc;
                    list-style-position: inside;
                }
            }
        }
    }
}
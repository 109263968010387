@mixin clear-outline {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline-style: none;
}

//@mixin set-sprite($x, $y) {
//    background: url('../img/sprite@2x.png') $x $y no-repeat;
//    background-size: 1200px 700px;
//}

//@mixin set-placeholder($color) {
//    &::-webkit-input-placeholder {
//        color: $color;
//    }
//
//    &:-moz-placeholder {
//        color: $color;
//    }
//
//    &::-moz-placeholder {
//        color: $color;
//    }
//
//    &:-ms-input-placeholder {
//        color: $color;
//    }
//}

@mixin center($horizontal: true, $vertical: true) {
    position: absolute;
    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    @else if ($horizontal) {
        left: 50%;
        -ms-transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
    @else if ($vertical) {
        top: 50%;
        -ms-transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        transform: translate(0, -50%);
    }
}

@mixin relative-center() {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin cf {
    zoom: 1;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin cf-overflow {
    overflow: hidden;
    *overflow: visible;
    zoom: 1;
}

@mixin MQ($canvas) {

    //320 - 767
    @if $canvas==S {
        @media only screen and (min-width: $S) and (max-width: $M - 1) {
            @content;
        }
    }

        //768 - 991
    @else if $canvas==M {
        @media only screen and (min-width: $M) and (max-width: $L - 1) {
            @content;
        }
    }

        //992 - 1199
    @else if $canvas==L {
        @media only screen and (min-width: $L) and (max-width: $XL - 1) {
            @content;
        }
    }

        //1200 - 1920
    @else if $canvas==XL {
        @media only screen and (min-width: $XL) and (max-width: $XXL) {
            @content;
        }
    }

    @else if $canvas==Splus {
        @media only screen and (min-width: $S + 1) {
            @content;
        }
    }
    @else if $canvas==Mplus {
        @media only screen and (min-width: $M + 1) {
            @content;
        }
    }
    @else if $canvas==Lplus {
        @media only screen and (min-width: $L + 1) {
            @content;
        }
    }
    @else if $canvas==XLplus {
        @media only screen and (min-width: $XL + 1) {
            @content;
        }
    }
    @else if $canvas==XXLplus {
        @media only screen and (min-width: $XXL + 1) {
            @content;
        }
    }

    @else if $canvas==MAXplus {
        @media only screen and (min-width: $max-width + 1) {
            @content;
        }
    }
    @else if $canvas==Sneg {
        @media only screen and (max-width: $S) {
            @content;
        }
    }
    @else if $canvas==SMneg {
        @media only screen and (max-width: $SM) {
            @content;
        }
    }
    @else if $canvas==Mneg {
        @media only screen and (max-width: $M) {
            @content;
        }
    }
    @else if $canvas==Lneg {
        @media only screen and (max-width: $L) {
            @content;
        }
    }
    @else if $canvas==XLneg {
        @media only screen and (max-width: $XL) {
            @content;
        }
    }

    @else if $canvas==XLQneg {
        @media only screen and (max-width: $XLQ) {
            @content;
        }
    }

    @else if $canvas==XLSneg {
        @media only screen and (max-width: $XLS) {
            @content;
        }
    }

    @else if $canvas==XXLneg {
        @media only screen and (max-width: $XXL) {
            @content;
        }
    }
    @else if $canvas==MAXneg {
        @media only screen and (max-width: $max-width) {
            @content;
        }
    }
}

//@mixin image-2x($image, $width, $height) {
//    @media (min--moz-device-pixel-ratio: 1.3),
//    (-o-min-device-pixel-ratio: 2.6/2),
//    (-webkit-min-device-pixel-ratio: 1.3),
//    (min-device-pixel-ratio: 1.3),
//    (min-resolution: 1.3dppx) {
//        /* on retina, use image that's scaled by 2 */
//        background-image: url($image);
//        background-size: $width $height;
//    }
//}

@mixin reset-button(){
    margin: 0;
    padding: 0;
    appearance: none;
    box-shadow: none;
    border-radius: 0;
    border: none;
    &:focus {
        outline: none;
    }
}

@mixin visually-hidden {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
}
//
//%articleCategoryButton {
//    display: inline-block;
//    background: $white;=
//    font-size: 1.6rem;
//    font-weight: 600;
//    padding: 14px 25px;
//    border-radius: 8px;
//    text-transform: uppercase;
//}
.enemy-liver, .choose-silimax {

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .enemy-box {
      width: calc(50% - 10px);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;

      @include MQ(Lneg) {
        width: 100%;

        &:nth-child(3) {
          order: 5;
        }
      }

      &.right {
        justify-content: right;

        @include MQ(Lneg) {
          justify-content: left;
        }
      }

      .enemy-image {
        display: flex;
        align-items: center;
        opacity: 0;

        @include MQ(Lneg) {
          justify-content: center;
        }

        img {
          width: 100%;
          padding: 0 50px 50px 0;

          @include MQ(Lneg) {
            width: 70%;
          }

          @include MQ(Mneg) {
            width: 100%;
          }
        }
      }

      .enemy-box-header {
        width: 100%;

        p {
          font-size: 3.2rem;
          margin-bottom: 50px;
          font-weight: 600;
          line-height: 1.25;

          @include MQ(XLneg) {
            font-size: 2.2rem;
          }
        }
      }

      .enemy-box-item {
        width: calc(50% - 10px);
        box-shadow: 0 5px 10px #43435C1A;
        border-radius: 20px;
        padding: 30px;
        background-color: $white;
        opacity: 0;

        @include MQ(Mneg) {
          width: 100%;
          margin-bottom: 20px;
          padding: 20px;
        }

        .ico {
          height: 160px;
          display: flex;
          align-items: center;
          justify-content: center;

          @include MQ(Mneg) {
            height: 100px;

            img {
              height: 85%;
            }
          }
        }

        .description {
          min-height: 50px;
          height: calc(100% - 160px);
          display: flex;
          align-items: center;
          justify-content: center;

          @include MQ(Mneg) {
            height: calc(100% - 100px);
          }

          h3 {
            color: $magenta;
            font-weight: 600;
            text-align: center;
            font-size: 2.6rem;
            line-height: 1.25;

            @include MQ(Mneg) {
              font-size: 1.8rem;
            }
          }
        }
      }
    }

    h2 {
      font-family: $PTSerif;
      color: $magenta;
      margin-bottom: 70px;

      @include MQ(Lneg) {
        margin-bottom: 40px;
      }
    }
  }

  &.start-animation {
    .container {
      .enemy-image {
        animation-name: zoomInLeft;
        animation-duration: .7s;
        animation-fill-mode: forwards;
      }

      .enemy-box {

        .enemy-box-item {
          animation-name: zoomIn;
          animation-duration: .7s;
          animation-fill-mode: forwards;
          animation-delay: 0.5s;
        }
      }
    }
  }
}

.choose-silimax {

  .container {

    h2 {
      margin-bottom: 15px;
    }

    .enemy-box {

      .enemy-box-header {

        h2 {
          color: $lemon;
        }
      }

      .enemy-box-item .description {

        h3 {
          color: $lemon;
        }
      }
    }
  }
}
#app > header > .container,
#app > section > .container,
#app > section > .outer-leaflet > .container,
#app > footer > .container {
    max-width: $max-content-width;
    padding: 0 40px;
    margin: 0 auto;
    overflow: hidden;

    @include MQ(Mneg) {
        padding: 0 20px;
    }
}

#app > section {
    padding: 60px 0;
}


.where-to-buy {
  background-color: $white;
  margin: 20px 0 40px;

  .container {
    display: flex;
    flex-wrap: wrap;

    @include MQ(XLneg) {
      display: block;
    }

    h2 {
      margin-right: 120px;
      font-size: 3rem;

      @include MQ(XLneg) {
        margin-bottom: 20px;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      @include MQ(Lneg) {
        display: block;
      }

      li {
        display: flex;
        align-items: center;

        @include MQ(Mneg) {
          justify-content: center;
        }

        @include MQ(Lneg) {
          margin-bottom: 20px;
        }

        a {
          display: flex;
          align-items: center;

          @include MQ(Mneg) {
            display: block;
          }
        }

        &:first-child {
          margin-right: 120px;

          @include MQ(Mneg) {
            margin-right: 0;
          }
        }

        .logo {
          margin-right: 20px;

          @include MQ(Mneg) {
            margin-right: 0;
            text-align: center;
          }
        }

        .logo-description {
          font-size: 1.8rem;

          @include MQ(Mneg) {
            text-align: center;
          }

          strong {
            font-weight: 600;
          }
        }
      }
    }
  }
}
#app .top {
  padding: 0;
}

.top {
  margin-top: 150px;

  @include MQ(XLneg) {
    margin-top: 80px;
  }

  .container {
    position: relative;
    display: flex;
    align-items: center;

    .arrow-down {
      position: absolute;
      @include center(true, false);
      bottom: 30px;

      @include MQ(Lneg) {
        display: none;
      }

      a {
        display: block;
        width: 45px;
        height: 45px;
        background: url('../img/ico-arrow-down.svg') no-repeat center center;
        background-size: 45px 45px;
      }
    }
  }

  .top-carousel {
    width: 100%;

    @include MQ(Lneg) {
      margin-top: 20px;
    }

    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }

  .top-carousel .carousel-item  {
    height: calc(100vh - 160px);
    min-height: 660px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @include MQ(Lneg) {
      height: auto;
      min-height: unset;
    }

    .top-image {
      width: 50%;
      display: flex;
      align-items: center;
      opacity: 0;

      img {
        width: 100%;

        &.image-two {
          width: 90%;
        }
      }

      @include MQ(Lneg) {
        width: 100%;
        justify-content: center;

        img {
          width: 60%;

          &.image-two {
            width: 50%;
          }
        }
      }
    }

    .top-description {
      width: 50%;
      display: flex;
      align-items: center;
      padding-left: 5vw;
      opacity: 0;

      @include MQ(Lneg) {
        width: 100%;

        .top-description-inner {
          width: 100%;
          text-align: center;
        }
      }

      .dots-wrapper {

        .slick-dots {
          display: flex;
          margin-bottom: 40px;

          li  {

            button {
              font-size: 0;
              width: 20px;
              height: 20px;
              border-radius: 20px;
              background-color: $yellow;
              margin-right: 10px;
              cursor: pointer;
            }

            &.slick-active {

              button {
                background-color: $lemon;
              }
            }
          }
        }
      }

      h1 {
        font-weight: 700;
        font-size: 6rem;
        line-height: 1.15;
        margin-bottom: 60px;

        @include MQ(XLQneg) {
          font-size: 4rem;
        }

        @include MQ(Lneg) {
          font-size: 3rem;
          text-align: center;
          margin-top: 40px;
          margin-bottom: 30px;
        }

        strong {
          color: $magenta;
        }
      }

      .top-button {
        display: inline-block;
        padding: 20px 50px;
        font-size: 1.8rem;
        font-weight: 700;
        color: $white;
        background-color: $magenta;
        border-radius: 45px;
      }
    }
  }

  &.start-animation {
    .container {
      .top-image {
        animation-name: zoomInLeft;
        animation-duration: .7s;
        animation-fill-mode: forwards;
      }

      .top-description {
        animation-name: fadeIn;
        animation-duration: .7s;
        animation-fill-mode: forwards;
        animation-delay: .7s;
      }
    }
  }
}




#app > header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: $white;
  z-index: 100;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 10px;
    background-color: $magenta;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 160px;

    @include MQ(XLneg) {
      height: 90px;
    }

    .silimax-logo {

      img {
        display: block;

        @include MQ(XLneg) {
          max-width: 180px;
        }

        @include MQ(Lneg) {
          max-width: 140px;
        }

        @include MQ(Mneg) {
          max-width: 120px;
        }
      }
    }

    .main-menu {

      @include MQ(Lneg) {
        display: none;

        &.mobile-menu {
          display: flex;
          align-items: center;
          justify-content: center;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: $white;
          height: 100vh;

          ul {
            display: block;

            li {

              a {
                display: block;
                padding: 20px 0;
                text-align: center;

                &:after {
                  bottom: 10px;
                }
              }
            }
          }
        }
      }

      ul {
        display: flex;

        li {

          a {
            font-size: 2rem;
            font-family: $Barlow;
            font-weight: 600;
            padding: 20px;
            position: relative;

            &:after {
              transition: .2s all ease-out;
              opacity: 0;
              background-color: $magenta;
              content: '';
              position: absolute;
              right: 20px;
              bottom: 0;
              left: 20px;
              height: 3px;
            }
          }

          a.active, a:hover {
            color: $magenta;
          }

          a.active:after {
            opacity: 1;
          }
        }
      }
    }

    .main-menu-toggle {
      display: none;

      @include MQ(Lneg) {
        display: block;
      }
    }
  }
}

.use {
  position: relative;
  padding-bottom: 30px;

  .container-full-width {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .how, .when {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 50px;
      left: 0;
      z-index: -1;
    }
  }

  .how {
    width: calc(50% - 10px);
    opacity: 0;

    @include MQ(Lneg) {
      width: 100%
    }

    &:before {
      background-color: #F6F9E3;
      border-radius: 0 20px 20px 0;

      @include MQ(Lneg) {
        border-radius: 0;
      }
    }

    .how-inner {
      width: 100%;
      max-width: 740px;
      margin-left: auto;
      margin-right: 0;
      padding: 50px 50px 0 40px;

      @include MQ(XLneg) {
        margin-right: auto;
      }
    }

    h2 {
      color: $lemon;
    }

    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      li {
        width: calc(50% - 10px);
        box-shadow: 0 5px 10px #43435C1A;
        border-radius: 20px;
        margin-top: 120px;
        position: relative;
        padding: 40px 20px 20px;
        text-align: center;
        background-color: $white;

        @include MQ(XLneg) {
          width: calc(100% - 50px);
          margin-left: 50px;
          margin-top: 30px;
          padding-top: 20px;
        }

        &:before {
          content: '';
          top: -70px;
          @include center(true, false);
          border-radius: 90px;
          width: 90px;
          height: 90px;
          background-color: $lemon;

          @include MQ(XLneg) {
            @include center(false, true);
            border-radius: 50px;
            width: 50px;
            height: 50px;
            left: -25px;
          }
        }

        &:after {
          content: '';
          top: -70px;
          @include center(true, false);
          width: 90px;
          height: 90px;

          @include MQ(XLneg) {
            @include center(false, true);
            width: 50px;
            height: 50px;
            left: -25px;
          }
        }

        &.one:after {
          background: url('../img/ico-one-pillow.svg') no-repeat center center;
          background-size: 20px 43px;

          @include MQ(XLneg) {
            background-size: 10px 21px;
          }
        }

        &.two:after {
          background: url('../img/ico-two-pillow.svg') no-repeat center center;
          background-size: 50px 43px;

          @include MQ(XLneg) {
            background-size: 25px 21px;
          }
        }
      }
    }
  }

  .when {
    width: calc(50% - 10px);
    opacity: 0;

    @include MQ(Lneg) {
      width: 100%;
    }

    &:before {
      background-color: #FBE8F0;
      border-radius: 20px 0 0 20px;

      @include MQ(Lneg) {
        border-radius: 0;
      }
    }

    .when-inner {
      width: 100%;
      max-width: 740px;
      padding: 50px 40px 0 80px;

      @include MQ(XLneg) {
        margin: 0 auto;
      }

      ul {

        li {
          background-color: $white;
          padding: 20px 35px;
          margin-bottom: 20px;
          box-shadow: 0 5px 10px #43435C1A;
          border-radius: 20px;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            @include center(false, true);
            left: -30px;
            width: 50px;
            height: 50px;
            border-radius: 50px;
            background: $magenta;
          }

          &:after {
            content: '';
            position: absolute;
            @include center(false, true);
            left: -12px;
            width: 15px;
            height: 15px;
            border-radius: 15px;
            background: $white;
          }

          p {
            font-size: 1.8rem;
          }
        }
      }
    }

    h2 {
      color: $magenta;
      margin-bottom: 60px;
    }
  }

  .outer-leaflet {
    background-color: white;
    margin-top: 30px;

    .container {
      padding: 35px 0;

      .leaflet {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @include MQ(XLneg) {
          display: block;
          text-align: center;
          padding: 0 40px;
        }

        a {
          display: inline-block;
          padding: 20px 145px 20px 40px;
          background: $magenta;
          color: $white;
          font-weight: 600;
          position: relative;
          border-radius: 45px;
          transition: opacity .2s ease-out;
          margin-left: 30px;

          @include MQ(XLneg) {
            margin-top: 20px;
          }

          &:after {
            content: '';
            position: absolute;
            @include center(false, true);
            right: 30px;
            width: 24px;
            height: 24px;
            background: url('../img/ico-download.svg') no-repeat center center;
            background-size: 24px 24px;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  &.start-animation {

    .how {
      animation-name: fadeInLeft;
      animation-duration: .7s;
      animation-fill-mode: forwards;
    }
    .when {
      animation-name: fadeInRight;
      animation-duration: .7s;
      animation-fill-mode: forwards;
    }
  }
}
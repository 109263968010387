.silimarin {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 150px;
    left: 0;
    background-color: #FBE8F0;
    z-index: -1;
  }

  .container {
    display: flex;
    flex-wrap: wrap;

    .silimarin-description {
      width: 65%;

      @include MQ(XLneg) {
        width: 50%;
      }

      @include MQ(Lneg) {
        width: 100%;
      }
    }

    .silimarin-image {
      width: 35%;
      opacity: 0;

      img {
        width: 150%;
        margin: 40px 0 0 -70%;

        @include MQ(XLneg) {
          width: 100%;
          margin: 40px 0 0 0;
        }

        @include MQ(Lneg) {
          width: 80%;
        }
      }

      @include MQ(XLneg) {
        width: 50%;
      }

      @include MQ(Lneg) {
        width: 100%;
      }
    }

    h2, h3 {
      color: $magenta;
      margin: 40px 0;
      width: 70%;
    }

    h3 {
      font-size: 4rem;

      @include MQ(XLneg) {
        font-size: 3rem;
      }
    }

    p {
      font-size: 3.2rem;
      font-weight: bold;
      width: 70%;

      @include MQ(XLneg) {
        font-size: 2.2rem;
        font-weight: bold;
        width: 100%;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      li {
        width: calc(33.33% - 20px);
        padding: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        box-shadow: 0 5px 10px #43435C1A;
        border-radius: 20px;
        background-color: $white;
        opacity: 0;

        @include MQ(XLneg) {
          width: calc(50% - 20px);
        }

        @include MQ(Lneg) {
          width: 100%;
        }

        h4 {
          font-size: 2.6rem;
          font-family: $Barlow;
          color: $magenta;
          margin-bottom: 10px;
        }

        p {
          font-size: 1.8rem;
          line-height: 1.55;
          width: 100%;
          font-weight: 400;
        }
      }
    }
  }

  &.start-animation {
    .container {
      .silimarin-image {
        animation-name: zoomInLeft;
        animation-duration: .7s;
        animation-fill-mode: forwards;
      }

      ul {

        li {
          animation-name: zoomIn;
          animation-duration: .7s;
          animation-fill-mode: forwards;
          animation-delay: 0.5s;
        }
      }
    }
  }
}